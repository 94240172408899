.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #222831;
    color: white;
    position: relative;
}

.hamburger {
    cursor: pointer;
    z-index: 1001;
}

.nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #393E46;
    padding: 0;
    justify-content: center;
    align-items: center;
    transition: right 0.3s ease-in-out;
    z-index: 999;
}

.navOpen {
    right: 0;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

nav ul li {
    color: white;
    font-size: 24px;
    cursor: pointer;
    margin: 40px;
    text-align: center;
    text-transform: uppercase;
}

nav ul li a {
    color: white;
    text-decoration: none;
}