/* Utility.module.css */

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: auto;
  max-width: 900px;
  background-color: #1a1a1a;
  color: #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: justify;
}

.textWrapper {
  max-width: 700px;
  width: 100%;
  padding: 10px;
}

.textWrapper h1 {
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
}

.textWrapper p {
  font-size: 1.15em;
  line-height: 1.7;
  margin-bottom: 16px;
  color: #dcdcdc;
}

.textWrapper ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.textWrapper li {
  margin-bottom: 12px;
  font-size: 1.1em;
  line-height: 1.6;
  color: #e0e0e0;
  text-align: left;
}

.textWrapper ul li::marker {
  color: #ff6347; /* Tomato color for bullets */
}

.contactButton {
  padding: 14px 28px;
  background-color: #ff6347; /* Tomato color */
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1em;
  font-weight: bold;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.contactButton:hover {
  background-color: #d14a3c;
}

/* Responsive adjustments for larger screens */
@media (min-width: 768px) {
  .content {
    flex-direction: row;
    padding: 40px;
    text-align: left;
  }

  .textWrapper {
    text-align: left;
  }

  .textWrapper h1 {
    font-size: 2.5em;
    text-align: left;
    margin-bottom: 25px;
  }

  .textWrapper p,
  .textWrapper li {
    font-size: 1.2em;
    line-height: 1.8;
  }

  .contactButton {
    font-size: 1.15em;
    padding: 14px 30px;
  }
}

/* Additional fine-tuning for very large screens */
@media (min-width: 1200px) {
  .content {
    max-width: 1000px;
    padding: 50px;
  }

  .textWrapper h1 {
    font-size: 2.8em;
  }

  .textWrapper p,
  .textWrapper li {
    font-size: 1.3em;
    line-height: 1.85;
  }
}
