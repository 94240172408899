@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
}

.App {
  font-family: "Aleo", serif;
  font-optical-sizing: auto;
  background-color: #222831;
}