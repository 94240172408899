.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0px 20px 20px 20px;
}

.imageWrapper {
    position: relative;
}

.image {
    width: 100%;
    height: auto;
    object-fit: cover;
    pointer-events: none;
    user-select: none;
}

@media (max-width: 1024px) {
    .gridContainer {
        grid-template-columns: 1fr;
        padding: 20px;
        gap: 20px;
    }
}