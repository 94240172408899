/* Inscription.module.css */
.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .iframeWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .iframe {
    width: 100%;
    height: 80vh;
    border: none;
  }
  